.main-box {
  margin: 2rem 0;
}

.main-card {
  min-width: 600px;
}

.rating-box {
  margin: 2rem 0;
}

#average-rating {
  height: 3rem;
}

.gold-star {
  color: #fece6d;
}

.grey-star {
  color: #e1dfe0;
}

.rating-number {
  font-size: 1.5rem;
  vertical-align: center;
  margin: auto 1rem auto 0;
}

.stars {
  align-self: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  min-width: 10rem;
  font-size: 1.5rem;
  margin: 0.2rem 0;
}

.existing-reviews__header {
  border-top: #e1dfe0 0.1rem solid;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding-top: 1.5rem;
}

.existing-review__paragraph {
  margin: auto 0;
  font-weight: bold;
}

.existing-review__text {
  font-weight: normal;
  color: #797874;
}

.star-left {
  background-image: url(./blank-star.svg);
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  width: .75rem;
  height: 1.5rem;
  margin: .1rem 0 .1rem .1rem;
}

.star-right {
  background-image: url(./blank-star.svg);
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  width: .75rem;
  height: 1.5rem;
  margin: .1rem .1rem .1rem 0;
  background-position: -0.75rem;
}

.filled-star-left {
  background-image: url(./filled-star.svg);
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  width: .75rem;
  height: 1.5rem;
  margin: .1rem 0 .1rem .1rem;
}

.filled-star-right {
  background-image: url(./filled-star.svg);
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  width: .75rem;
  height: 1.5rem;
  margin: .1rem .1rem .1rem 0;
  background-position: -0.75rem;
}

.review-title {
  margin: 0.5rem 0 1.5rem;
}

.review-header {
  font-weight: normal;
}

.review-section {
  margin: 1.5rem 0;
}

.review-button {
  padding: 0.5rem 2rem;
}

.delete-reviews-button {
  margin: 1rem 0;
  padding: 0.5rem 2rem;
}

.no-border {
  border: none;
}

.error-message {
  color: red;
}